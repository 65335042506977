// TODO: Figure out a way to properly type env vars without trailing !s (non-null assertion operator)
export const IFRAME_URL = process.env.IFRAME_URL! // This is the entrypoint for the `widgets` SPA
export const IFRAME_ID = 'bw-widget-iframe'
export const IFRAME_CLASS = 'bw-widget-styles'
export const IFRAME_LOADER_CLASS = 'iframe-loader'
export const SNIPPET_SELECTOR = '.mindbody-widget'
export const UNIQUE_WINDOW_IDENTIFIER = 'bw-widget-unique-identifier'
export const DEFAULT_ERROR_MESSAGE =
  'Please double check your Mindbody Branded Web Widget Snippet and try again.'
export const WIDGET_TYPES = {
  Appointments: {
    type: 'Appointments',
    entryPoint: 'services',
  },
  Schedules: {
    type: 'Schedules',
    entryPoint: 'schedule',
  },
} as const
