import { customAlphabet } from 'nanoid'
import { z } from 'zod'
import { IFRAME_CLASS, IFRAME_ID, WIDGET_TYPES } from '@/constants'
import { WidgetAttributesSchema } from '@/schemas/WidgetAttributesSchema'

const nanoid = customAlphabet('1234567890abcdef', 5)

export function createIframe(
  widgetAttributes: z.infer<typeof WidgetAttributesSchema>,
  iFrameUrl: string,
) {
  const { widgetId, widgetType, preview } = widgetAttributes
  const iframe = document.createElement('iframe')
  iframe.setAttribute('id', `${IFRAME_ID}-${nanoid()}`)
  iframe.setAttribute('class', IFRAME_CLASS)

  let iframeUrl = new URL(iFrameUrl)

  const previewSegment = preview === 'true' ? 'preview' : 'view'
  const widgetTypeSegment = WIDGET_TYPES[widgetType].type
  const widgetEntryPointSegment = WIDGET_TYPES[widgetType].entryPoint
  const combinedUrlString =
    `${iFrameUrl}/widgets/${widgetTypeSegment}/${previewSegment}/${widgetId}/${widgetEntryPointSegment}`.toLowerCase()

  iframeUrl = new URL(combinedUrlString)

  iframe.setAttribute('src', iframeUrl.toString())

  return iframe
}
